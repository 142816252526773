@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-image: radial-gradient(
    hsla(215 28% 17%/0.2) 0.5px,
    hsla(0 0% 95%/1) 0.5px
  );
  min-height: 100dvh;
  background-size: 5px 5px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  /* padding: 2.5rem; */
}

.slide-up {
  animation: fadeInAnimation 300ms;
}

@keyframes fadeInAnimation {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
